<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('common.monthly') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" :startDefault="startDefault" :dateProps="dateProps" />
             <button class="btn-search ml-5" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
         <!-- <a class="btn-table" @click="onChangeType('calendar')">{{ $t('button.calendarType') }}</a>
         <a class="btn-table" @click="onChangeType('table')">{{ $t('button.tableType') }}</a> -->
         <p class="reportcomment">{{ $t('txt.reportData') }}</p>
      </div>
      <memo></memo>
    </div>

    <div class="main-contents-wrapper">
      <h2 class="tableName">{{ $t('common.sumMonthly') }}</h2>
      <div class="table-wrapper">
         <table class="mainTable headTable">
           <tbody>
            <tr>
              <td>
                <ul>
                  <li>{{ $t('searchArea.date') }}</li>
                  <li>{{total.days}}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('common.deposit') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.cashInAmt < 0 }">{{ numberWithCommas(total.cashInAmt) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('common.withdraw') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.cashOutAmt < 0 }">{{ numberWithCommas(total.cashOutAmt) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.profit') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.cashProfit < 0 }">{{ numberWithCommas(total.cashProfit) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.totalBetAmt') }}</li>
                  <li>
                    <span>{{numberWithCommas(total.betAmt)}}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.totalWinMoney') }}</li>
                  <li>
                    <span>{{numberWithCommas(total.betWinAmt)}}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.totalRollMoney') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.rollingAmt < 0 }">{{ numberWithCommas(total.rollingAmt) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.wl') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.betWlAmt < 0 }">{{ numberWithCommas(total.betWlAmt) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.haveAmt') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.memCashAmt < 0 }">{{ numberWithCommas(total.memCashAmt) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.havePoint') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.memPointAmt < 0 }">{{ numberWithCommas(total.memPointAmt) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.depositManager') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.adminCashPayment < 0 }">{{ numberWithCommas(total.adminCashPayment) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.withdrawManager') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.adminCashPayback < 0 }">{{ numberWithCommas(total.adminCashPayback) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.head.pointManager') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.adminPointPayment < 0 }">{{ numberWithCommas(total.adminPointPayment) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.body.money') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.memCashAmt < 0 }">{{ numberWithCommas(total.memCashAmt) }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{{ $t('table.body.pointAmt') }}</li>
                  <li>
                    <span :class="{ 'fc-red': total.memPointAmt < 0 }">{{ numberWithCommas(total.memPointAmt) }}</span>
                  </li>
                </ul>
              </td>
            </tr>
           </tbody>
         </table>
      </div>

      <div v-if="type == 'table'" class="min-w1600"><!-- 테이블 형-->
        <h2 class="tableName">{{ $t('common.table') }}</h2>
        <div class="table-wrapper">
           <table class="mainTable calTable">
             <thead>
                <tr>
                  <th>{{ $t('searchArea.date') }}</th>
                  <th>{{ $t('common.deposit') }}</th>
                  <th>{{ $t('common.withdraw') }}</th>
                  <th>{{ $t('cash.profit') }}</th>
                  <th>{{ $t('table.head.totalBetMoney') }}</th>
                  <th>{{ $t('table.head.totalWinMoney') }}</th>
                  <th>{{ $t('table.head.wl') }}</th>
                  <th>{{ $t('table.head.totalRollMoney') }}</th>
                  <th>{{ $t('table.head.depositManager') }}</th>
                  <th>{{ $t('table.head.withdrawManager') }}</th>
                  <th>{{ $t('table.body.money') }}</th>
                  <th>{{ $t('table.body.pointAmt') }}</th>
                </tr>
             </thead>
             <tbody>
             <template v-for="(item, idx) in days" :key="idx">
               <tr v-for="child in item" :key="child.day">
                 <template v-if="child.data.days">
                   <td>{{ child.data.days }}</td>
                   <td :class="{ 'fc-red': child.data.cashInAmt < 0 }">{{ numberWithCommas(child.data.cashInAmt) }}</td>
                   <td :class="{ 'fc-red': child.data.cashOutAmt < 0 }">{{ numberWithCommas(child.data.cashOutAmt) }}</td>
                   <td :class="{ 'fc-red': child.data.cashProfit < 0 }">{{ numberWithCommas(child.data.cashProfit) }}</td>
                   <td :class="{ 'fc-red': child.data.betAmt < 0 }">{{ numberWithCommas(child.data.betAmt) }}</td>
                   <td :class="{ 'fc-red': child.data.betWinAmt < 0 }">{{ numberWithCommas(child.data.betWinAmt) }}</td>
                   <td :class="{ 'fc-red': child.data.betWlAmt < 0 }">{{ numberWithCommas(child.data.betWlAmt) }}</td>
                   <td :class="{ 'fc-red': child.data.rollingAmt < 0 }">{{ numberWithCommas(child.data.rollingAmt) }}</td>
                   <td :class="{ 'fc-red': child.data.adminCashPayment < 0 }">{{ numberWithCommas(child.data.adminCashPayment) }}</td>
                   <td :class="{ 'fc-red': child.data.adminCashPayback < 0 }">{{ numberWithCommas(child.data.adminCashPayback) }}</td>
                   <td :class="{ 'fc-red': child.data.memCashAmt < 0 }">{{ numberWithCommas(child.data.memCashAmt) }}</td>
                   <td :class="{ 'fc-red': child.data.memPointAmt < 0 }">{{ numberWithCommas(child.data.memPointAmt) }}</td>
                 </template>

               </tr>
             </template>
             </tbody>
           </table>
        </div>
      </div><!-- 테이블 형-->

      <div v-if="type == 'calendar'" class="min-w1600"><!-- 달력 형-->
        <h2 class="tableName">{{ $t('common.calendar') }}</h2>
        <div class="table-wrapper" style="display: flex">
          <table class="mainTable calTable" style="table-layout:fiexd;width: calc(100% / 8 * 7);">
            <thead>
            <tr>
              <th style="color:#e50000">{{ $t('common.sun') }}</th>
              <th>{{ $t('common.mon') }}</th>
              <th>{{ $t('common.tue') }}</th>
              <th>{{ $t('common.wed') }}</th>
              <th>{{ $t('common.thu') }}</th>
              <th>{{ $t('common.fri') }}</th>
              <th>{{ $t('common.sat') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in days" :key="idx">
              <td v-for="child in item" :key="child.day">
                <span class="week_day">{{ child.day }}</span>
                <div class="one_day_wrap">
                  <div v-if="Object.keys(child.data).length !== 0" class="one_day">
                    <div>
                      {{ $t('table.head.totalBetMoney') }}: <span :class="{ 'fc-red': child.data.betAmt < 0 }">{{ numberWithCommas(child.data.betAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.totalWinMoney') }}: <span :class="{ 'fc-red': child.data.betWinAmt < 0 }">{{ numberWithCommas(child.data.betWinAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.wl') }}: <span :class="{ 'fc-red': child.data.betWlAmt < 0 }">{{ numberWithCommas(child.data.betWlAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.totalRollMoney') }}: <span :class="{ 'fc-red': child.data.rollingAmt < 0 }">{{ numberWithCommas(child.data.rollingAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('common.deposit') }}: <span :class="{ 'fc-red': child.data.cashInAmt < 0 }">{{ numberWithCommas(child.data.cashInAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('common.withdraw') }}: <span :class="{ 'fc-red': child.data.cashOutAmt < 0 }">{{ numberWithCommas(child.data.cashOutAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('cash.profit') }}: <span :class="{ 'fc-red': child.data.cashProfit < 0 }">{{ numberWithCommas(child.data.cashProfit) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.depositManager') }}: <span :class="{ 'fc-red': child.data.adminCashPayment < 0 }">{{ numberWithCommas(child.data.adminCashPayment) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.withdrawManager') }}: <span :class="{ 'fc-red': child.data.adminCashPayback < 0 }">{{ numberWithCommas(child.data.adminCashPayback) }}</span>
                    </div>
                    <div>
                      {{ $t('table.body.money') }}: <span :class="{ 'fc-red': child.data.memCashAmt < 0 }">{{ numberWithCommas(child.data.memCashAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('table.body.pointAmt') }}: <span :class="{ 'fc-red': child.data.memPointAmt < 0 }">{{ numberWithCommas(child.data.memPointAmt) }}</span>
                    </div>

                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="mainTable calTable b-l" style="width: calc(100% / 8)">
            <thead>
            <tr>
              <th>{{ $t('common.weekly') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in weeks" :key="idx">
              <td>
                <span class="week_day">{{ idx + 1 }} {{ $t('searchArea.weeks') }}</span>
                <div class="one_day_wrap">
                  <div v-if="Object.keys(item).length !== 0" class="one_day">
                    <div>
                      {{ $t('table.head.totalBetMoney') }}: <span :class="{ 'fc-red': item.betAmt < 0 }">{{ numberWithCommas(item.betAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.totalWinMoney') }}: <span :class="{ 'fc-red': item.betWinAmt < 0 }">{{ numberWithCommas(item.betWinAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.wl') }}: <span :class="{ 'fc-red': item.betWlAmt < 0 }">{{ numberWithCommas(item.betWlAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.totalRollMoney') }}: <span :class="{ 'fc-red': item.rollingAmt < 0 }">{{ numberWithCommas(item.rollingAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('common.deposit') }}: <span :class="{ 'fc-red': item.cashInAmt < 0 }">{{ numberWithCommas(item.cashInAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('common.withdraw') }}: <span :class="{ 'fc-red': item.cashOutAmt < 0 }">{{ numberWithCommas(item.cashOutAmt) }}</span>
                    </div>
                    <div>
                      {{ $t('cash.profit') }}: <span :class="{ 'fc-red': item.cashProfit < 0 }">{{ numberWithCommas(item.cashProfit) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.depositManager') }}: <span :class="{ 'fc-red': item.adminCashPayment < 0 }">{{ numberWithCommas(item.adminCashPayment) }}</span>
                    </div>
                    <div>
                      {{ $t('table.head.withdrawManager') }}: <span :class="{ 'fc-red': item.adminCashPayback < 0 }">{{ numberWithCommas(item.adminCashPayback) }}</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div><!-- 달력 형-->
    </div>
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector_monthly'
import subTitle from '@/components/main/PageSubTitle'
import { reportDaily, getCode } from '@/api/member.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import { format } from 'date-fns'
import Memo from '@/components/common/memo'

export default {
  name: 'BetList',
  components: {
    SearchFilter,
    DateSelector,
    subTitle,
    Pagination,
    Memo
  },
  data () {
    return {
      type: 'calendar',
      dateProps: {
        start: true,
        end: false
      },
      reqData: {
        startDate: ''
      },
      total: {},
      tableData: [],
      days: [],
      weeks: [],
      monthly: {
        cashInAmt: 0,
        cashOutAmt: 0,
        pointAmt: 0,
        wlAmt: 0,
        memCashAmt: 0,
        memPointAmt: 0,
        discrepancyAmt: 0,
        cashManualAmt: 0,
        pointManualAmt: 0
      },
      startDefault: ''
    }
  },
  methods: {
    onChangeType (type) {
      this.type = type
    },
    async pageSeach () {
      this.setCalendar(this.reqData.startDate)
      await this.setTableData()
    },
    getDate (date) {
      return date
        .toISOString()
        .split('T')[0]
        .split('-')
        .map(v => Number(v))
    },
    setCalendar (date) {
      this.days = []
      this.weeks = []
      for (const key in this.monthly) {
        this.monthly[key] = 0
      }
      let today = new Date()
      if (date) {
        today = new Date(date)
      }
      const nowMonth = today.getMonth()

      const y = format(today, 'Y')
      const m = format(today, 'M')

      // const [y, m] = this.getDate(new Date(today.setMonth(nowMonth)))
      const lastDay = (this.getDate(new Date(y, m, 0)).pop() * 1) + 1 // lastday
      const day = new Date([y, m, 1].join('-')).getDay() * 1 // week
      const maxDay = Math.ceil((day + lastDay) / 7) * 7
      const days = []
      for (let i = 1; i <= maxDay; i++) {
        const diff = i - day
        let d = ''
        if (diff <= lastDay && i > day) {
          d = diff
        }
        days.push(d)
        console.log(d)
      }
      const cnt = 0
      let arrays = []
      days.forEach((el, idx) => {
        const count = idx + 1
        const obj = {
          day: el,
          data: {}
        }
        arrays.push(obj)
        if (count % 7 === 0) {
          console.log('next')
          this.days.push(arrays)
          arrays = []
        }
      })
      console.log(this.days)
    },
    setStartDate (date) {
      // console.log(date)
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setDate(1)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    async setTableData () {
      this.emitter.emit('Loading', true)
      const res = await reportDaily(this.reqData)
      console.log('reportDaily : ', res)
      const daysObj = res.data.list
      this.total = res.data.total
      for (const key in this.monthly) {
        for (const item of daysObj) {
          if (!item.hasOwnProperty(key)) {
            item[key] = 0
          }
        }
      }
      for (const item of daysObj) {
        const dayStr = item.days.split('-')
        const day = Number(dayStr[2])
        for (const obj of this.days) {
          for (const child of obj) {
            if (child.day === day) {
              child.data = item
            }
          }
        }
      }
      console.log(this.days)
      for (const item of this.days) {
        const obj = {
          cashInAmt: 0,
          cashOutAmt: 0,
          cashProfit: 0,
          betAmt: 0,
          betWinAmt: 0,
          rollingAmt: 0,
          betWlAmt: 0,
          memCashAmt: 0,
          memPointAmt: 0,
          userCashPayment: 0,
          userCashPayback: 0,
          adminCashPayment: 0,
          adminCashPayback: 0,
          adminPointPayment: 0
        }
        for (const day of item) {
          if (Object.keys(day.data).length !== 0) {
            obj.cashInAmt += Number(day.data.cashInAmt)
            obj.cashOutAmt += Number(day.data.cashOutAmt)
            obj.cashProfit += Number(day.data.cashProfit)
            obj.betAmt += Number(day.data.betAmt)
            obj.betWinAmt += Number(day.data.betWinAmt)
            obj.rollingAmt += Number(day.data.rollingAmt)
            obj.betWlAmt += Number(day.data.betWlAmt)
            obj.memCashAmt += Number(day.data.memCashAmt)
            obj.memPointAmt += Number(day.data.memPointAmt)
            obj.userCashPayment += Number(day.data.userCashPayment)
            obj.userCashPayback += Number(day.data.userCashPayback)
            obj.adminCashPayment += Number(day.data.adminCashPayment)
            obj.adminCashPayback += Number(day.data.adminCashPayback)
            obj.adminPointPayment += Number(day.data.adminPointPayment)
          }
        }
        this.weeks.push(obj)
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.setCalendar()
    this.setStartDate()
    await this.setTableData()
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.b-l {position: relative;}
.b-l::before {content: ''; width: 2px;height: 100%;background: #ffb66e;position: absolute;left: 0;}
.tableName {margin: 10px 0;}
.btn-table {height: 26px;background: #353535;color: #fff;margin-left: 15px;display: flex;align-items: center;padding: 0 10px;cursor: pointer;font-size: 14px;}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.mainTable{
  table-layout: fixed;
}
.mainTable td {
  position: relative;
  border: 3px solid #e9e9e9;
}
.calTable tr:nth-child(even) td {background: #f5f5f5;}
.calTable th {
  height: 26px;
  background: #cfcfcf;
  border: 3px solid #e9e9e9;
  font-size: 11px;
  color: #1e1e1e;
}
.calTable td {text-shadow: none;}
.headTable td {
  padding: 2px;
}
.headTable ul {
  height: 100%;
}
.headTable ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 25px);
  font-size: 14px;color: #616161;
  font-family: 'roboto';
}
.headTable ul li span {font-family: 'roboto';}
.headTable ul li:nth-child(1) {
  height: 25px;
  background-color: #cfcfcf;
  color: #585963;
  font-size: 11px;
}
.week_day {
  position: absolute;
  left: 4px;
  top: 4px;
  font-weight: 900;
}
.week_no {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 4px;
}
.one_day_wrap {text-align: right; padding: 9px 7px; height: 220px; box-sizing: border-box;font-size: 12px;color: #585963;}
.one_day {display: flex;flex-direction: column;gap: 3px;}
.one_day > div {position: relative;}
.one_day > div:nth-child(4),
.one_day > div:nth-child(9) {margin-bottom: 5px;padding-bottom: 7px;}
.one_day > div:nth-child(4)::before,
.one_day > div:nth-child(9)::before {content: '';position: absolute;left: 0;bottom: -2px;width: 100%;height: 2px;background: #e8e8e8;}
.one_day > div:nth-child(4)::after,
.one_day > div:nth-child(9)::after {content: '';position: absolute;left: 0;bottom: -4px;width: 100%;height: 2px;background: #fff;}

.table-wrapper {
  padding: 0;
  box-shadow: none;
}
.mainTable thead {
  box-shadow: none;
  border: 0;
  background: none;
}
.table-wrapper table:nth-child(1) th:last-child, .table-wrapper table:nth-child(1) td:last-child {
  border-right: 0;
}
</style>
